<!--
--------------------------------------------------------------------------------
<copyright file="BendingAvailability.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.time_scale_h') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_stat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_stat2') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.evaluated_machine_status') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.bend') }}:</span>
          <p class="text">{{ $t('widget_info_text.bend_availab_txt') }}</p>
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.program') }}:</span>
          <p class="text">{{ $t('widget_info_text.bend_availab_program_txt') }}</p>
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.setup') }}</span>
          <p class="text">{{ $t('widget_info_text.bend_availab_setup_txt') }}</p>
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.error') }}</span>
          <p class="text">{{ $t('widget_info_text.bend_availab_error_txt') }}</p>
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.warning') }}:</span>
          <p class="text">{{ $t('widget_info_text.bend_availab_warning_txt') }}</p>
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.bars_txt_maq_stat') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.top_bar') }}</span> –
          <span class="blue-bar">{{ $t('widget_info_text.blue') }}</span> :
          {{ $t('widget_info_text.selected_period') }}
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.bottom_bar') }}</span> -
          <span class="green-bar">{{ $t('widget_info_text.green') }}</span
          >:
          {{ $t('widget_info_text.previous_period') }}
        </li>
        <li class="list-item">
          <span class="demi-bold">{{ $t('widget_info_text.bar_length') }}</span> :
          {{ $t('widget_info_text.bar_len_txt') }}
        </li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.bars_info_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="text tip-bkg">{{ $t('widget_info_text.bars_tip_txt_cat') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
  },
})
export default class BendingAvailability extends Vue {}
</script>
